$primary-color: #343a40;
$title-background-color:#e9ecef;
$team-menu-padding: .75rem 1rem 0.1rem;
$team-menu-background-color: rgba(48,48,48,.8588235294117647);
$team-menu-link-color: #bcbcbc;

// .pt1 {
//     padding-top: 0.5rem;
// }
// .pt2 {
//     padding-top: 1rem;
// }
// .pt4 {
//     padding-top: 1.5rem;
// }
// .pt6 {
//     padding-top: 2rem;
// }

// .mt1 {
//     margin-top: 0.5rem;
// }
// .mt2 {
//     margin-top: 1rem;
// }
// .mt4 {
//     margin-top: 1.5rem;
// }
// .mt6 {
//     margin-top: 2rem;
// }


.team-menu {
    .dropdown-menu.show {
        display: inline-table;
    }
    
    .breadcrumb {
        background-color: $team-menu-background-color;
        border-radius: 0;
        padding: $team-menu-padding;
    }
    .dropdown-toggle {
        padding-top: 0!important;
    }
    .dropdown-toggle.nav-link {
        color: $team-menu-link-color;
    }
}

.breadcrumb-item {
    padding: 0px;
    a {
        color: $team-menu-link-color;
    }
    //Muligens display: inline-block kan hjelpe på dropdown for subteams.
}

.kw-content-wrapper {
    max-width: 1250px;
}

.kw-title {
    background-color: $title-background-color;
    padding: $team-menu-padding;
    text-align: center;

    width: 100%;
    border-radius: 0px;
} 

h1.kw-tmb-title {
    font-size: 1.3rem;
}

.kw-backbutton {
    padding-top: 1rem;
    cursor: pointer;
}

.kw-newslink {
    cursor: pointer;
}

.kw-card {
    padding-bottom: 5px;
}

.kw-image-article-content {
    max-width: 40%; 
    float: left;
    padding-right: 5px;
    padding-bottom: 5px;
}

.kw-ingress {
    font-weight: bold;
    color: #505050;
}

.underlined {
    border-bottom: solid 1px #cfcfcf;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.bold {
    font-weight: bold;
}

.fb-like {
    float: right !important;
}

.dropdown {
    padding-bottom: 5px;
}

.dropdown-toggle,
.dropdown-menu {
  width: 100%;
}

.level-1 {
    padding-left: 10px;
    font-weight: bold;
}

.level-2 {
    padding-left: 20px;
    font-weight: bold;
}

.level-3 {
    padding-left: 30px;
}

// Tilpasninger for de enkelte lag under her.
.vintest_artisteer3 {
    $primary-color: #832121;
    $secondery-color: #83212180;
    $team-menu-background-color: #e9ecef;
    $active-color: #b93333;

    .bg-dark {
        background-color: $primary-color!important;
    }

    a {
        color: $primary-color;
        &.navbar-brand:visited {
            color:white;
        }
        &.dropdown-item:active {
            background-color: $secondery-color;
        }
    }

    // a {
    //     color: #832121; 
    // }
    // a:hover {
    //     color: #832121; 
    // }
    
    // .page-link {
    //     color: #832121; 
    // }
    
    // .page-item.active .page-link {
    //     background-color: #832121;
    //     border-color: #832121;
    // }
    
    a:visited {
        color: $primary-color;
    }

    .dropdown-item.active {
        color: white;
        background-color: $active-color;
    }
    
    // a.menulink {
    //     color: $primary-color;
    // }
    
    // a.menulink:visited {
    //   color: $primary-color;
    // }
    
    .kw-backbutton {
        color: $primary-color;
    }
    
    &.team-menu {
        .breadcrumb {
            background-color: #e9ecef;
        }
        .dropdown-toggle.nav-link {
            color: $primary-color;
        }
    }

    .page-link:hover {
        color: $primary-color;
    }

    .page-item.active .page-link {
        background-color: $primary-color;
        border-color: $primary-color;
        &:hover {
            color: $primary-color;
        }
    }
}